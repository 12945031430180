.container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10%;
}

.chart {
  padding-left: 1px;
  padding-right: 1px;
  min-height: 400px;
}